<!--
  Vista para mostrar sección de Contacto
  Tiene datos sobre como contactar a la empresa, así como correo y ubicación en donde estan las oficinas
  y un apartado con unos campos de texto para enviar un correo mediante la plataforma.
-->
<template>
  <v-container fluid class="pa-0">
    <!--<v-row>
      <v-col cols="12" offset-sm="1" sm="10">

      </v-col>
    </v-row>-->

    <v-row justify="center" align="center" class="my-5">
      <v-col cols="12" sm="10" md="5" xl="5" class="text-center">
        <v-row justify="center">
          <v-col cols="12">
            <h1 class="display-2 text-center mb-3">¿Tienes alguna duda?</h1>
            <p>¡Ponte en contacto con nosotros! En WeChamber siempre estamos dispuestos a ayudarte, déjanos un mensaje y pronto nos pondremos en contacto contigo.</p>
          </v-col>
          <v-col cols="8" sm="4" md="8" class="py-sm-0">
            <v-row justify="center">
              <v-col cols="10" class="my-5">
                <v-img class="mb-10" src="@/assets/images/landing/contactImage.svg"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="11" sm="8" md="5" lg="4" class="pt-0 pt-sm-3 mx-md-5 mx-xl-10 my-5">
        <v-card elevation="0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="center" class="py-5 px-5 px-md-10">
              <v-col cols="12" class="text-center pa-0">
                <h2 class="display-1 font-weight-medium">Mensaje</h2>
                <v-divider class="mt-2 mb-5 test"></v-divider>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                <v-text-field
                  prepend-inner-icon="fas fa-user"
                  v-model.trim="message.name"
                  label="Nombre"
                  required
                  filled
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                <v-text-field
                  prepend-inner-icon="fas fa-at"
                  v-model.trim="message.email"
                  label="Correo electrónico"
                  required
                  filled
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-text-field
                  counter
                  prepend-inner-icon="fas fa-question-circle"
                  v-model.trim="message.topic"
                  label="Asunto"
                  required
                  filled
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-textarea
                  counter
                  prepend-inner-icon="fas fa-envelope"
                  v-model.trim="message.message"
                  label="Mensaje"
                  filled
                  rounded
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="py-1">
                <v-row justify="center" class="mb-3">
                  <v-btn
                    :disabled="!valid"
                    color="accent"
                    large
                    class="black--text font-weight-bold"
                    rounded
                    @click="sendMessage()"
                  >Enviar Mensaje</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12" sm="10" offset-sm="1">
        <span class="d-flex align-center">
          <v-icon
            large
            color="secondary"
          >
            mdi-email
          </v-icon>
          <span class="ml-3 font-weight-bold">Puedes enviarnos un mensaje a <span class="secondary--text">contacto@wechamber.mx</span></span>
        </span>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1">
        <span class="d-flex align-center">
          <v-icon
            large
            color="red"
          >
            fas fa-map-marker-alt
          </v-icon>
          <span class="ml-3 font-weight-bold">León, Guanajuato</span>
        </span>
      </v-col>
    </v-row>
    <landing-footer></landing-footer>
  </v-container>
</template>
<script>
import { validations } from "@/share/validations.js";
import { mapMutations } from "vuex";
import LandingFooter from "@/components/landing/LandingFooter";
export default {
  components: {
    LandingFooter
  },
  data() {
    return {
      position:  { lat: 21.172715, lng: -101.733435 },
      valid: true,

      message: {
        name: "",
        email: "",
        topic: "",
        message: ""
      },

      message_base: { name: "", email: "", topic: "", message: "" },

      info: [
        {
          info:
            "Bulevar Campestre 403 piso 2, Jardines del moral, 37160, León, Gto.",
          icon: "fas fa-map-marker-alt"
        },
        { info: "contacto@wechamber.mx", icon: "fas fa-envelope" }
      ]
    };
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    /**
     * Función para validar los datos ingresados en la caja de contacto.
     */
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    sendMessage() {
      this.show({text:"¡Mensaje enviado éxito!"});
      if (this.validate()) {
        // Object.assign(this.message, this.message_base)
        // this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
<style scoped>
  .test {
    border-width: 2px !important;
    border-color: #d6d6d6 !important;
  }
</style>
